import type {
  components,
  SelectableItem,
  SpAppProduct,
  SpAppVertical,
} from '@/types';
import type { SparkButtonProps } from '#/components/generic/SparkButton/types';

export const PATHMAP: Record<SpAppProduct, string> = {
  'lng-freight-platform': 'freight',
  'lng-basis-platform': 'cargo',
  'access-platform': 'access',
  'connect-platform': 'connect',
  'gas-leba-platform': '/gas',
};

export const INTRO_PAGES: Partial<Record<SpAppProduct, string>> = {
  'lng-freight-platform': '/freight-intro',
  'lng-basis-platform': '/cargo-intro',
  'access-platform': '/access-intro',
} as const;

export const BUTTON_COLOR: Record<SpAppProduct, SparkButtonProps['color']> = {
  'lng-freight-platform': 'green',
  'lng-basis-platform': 'basis',
  'access-platform': 'access',
  'connect-platform': 'basis-connect',
  'gas-leba-platform': 'gas',
};

export const PLATFORM_SUFFIX = '-' + 'platform';
export const PRODUCT_NAMES: Record<SpAppProduct, string> = {
  'lng-freight-platform': 'LNG Freight',
  'lng-basis-platform': 'LNG Cargo',
  'access-platform': 'Access',
  'connect-platform': 'Connect',
  'gas-leba-platform': 'Gas',
};

export const FreightPriceAssessmentTypes = [
  {
    name: 'Physical',
    value: 'lng-freight::physical',
  },
  {
    name: 'FFA',
    value: 'lng-freight::financial',
  },
];

export const BasisPriceAssessmentTypes: SelectableItem<
  components['schemas']['PriceAssessmentEnum']
>[] = [
  {
    name: 'Physical',
    value: 'lng-basis::physical',
  },
  {
    name: 'Financial',
    value: 'lng-basis::financial',
  },
];

export type Product = {
  id: SpAppVertical;
  name: string;
  icon: string;
  color: string;
  bgColor: string;
  description: string;
};

export const ALL_PRODUCTS: Product[] = [
  {
    id: 'lng-freight',
    name: PRODUCT_NAMES['lng-freight-platform'],
    icon: 'freight',
    color: 'text-green',
    bgColor: 'bg-green-100',
    description: 'Browse product features and plans',
  },
  {
    id: 'lng-basis',
    name: PRODUCT_NAMES['lng-basis-platform'],
    icon: 'basis',
    color: 'text-basis',
    bgColor: 'bg-basis-100',
    description: 'Browse product features',
  },
  {
    id: 'access',
    name: PRODUCT_NAMES['access-platform'],
    icon: 'access',
    color: 'text-access',
    bgColor: 'bg-access-50',
    description: 'Browse product features',
  },
];

export const COMMODITY = { LNG: 'lng', OIL: 'oil' };
export const ASSESSMENT_TYPE = { FINANCIAL: 'financial', PHYSICAL: 'physical' };

export const SubscriptionPlanDisplayEnum: Record<
  components['schemas']['SubscriptionPlanEnum'],
  string
> = {
  'basic-plan': 'Basic Plan',
  'trial-plan': 'Trial Plan',
  'premium-plan': 'Premium Plan',
  'no-plan': 'No Plan',
};

export const features = {
  FREIGHT_DASHBOARD: 'dashboard',
  FREIGHT_BASIC_DASHBOARD: 'basic-dashboard',
  FREIGHT_SETTLEMENT_TRACKER: 'settlement-tracker',
  FREIGHT_SPREAD: 'spread',
  FREIGHT_PRICE_EVOLUTION: 'price-evolution',
  FREIGHT_PRICE_CALCULATOR: 'round-trip-calculator',
  FREIGHT_DISCOVER: 'discover',
  FREIGHT_PHYSICAL_PREFIX: 'freight-physical-',
  FREIGHT_PHYSICAL_SPARK30: 'freight-physical-spark30',
  FREIGHT_PHYSICAL_SPARK25: 'freight-physical-spark25',
  FREIGHT_OPTI: 'opti',
  FREIGHT_PRICE_ALERTS: 'price-alerts',
  FREIGHT_ALERT_CENTRE: 'alert-centre',
  FREIGHT_NEUTRAL_INTRO: 'freight-neutral-intro',
  FREIGHT_FLOATING_STORAGE: 'freight-floating-storage',
  FREIGHT_FLOATING_STORAGE_INTRO: 'freight-floating-storage-intro',
  FREIGHT_SPARK_DATA: 'spark-data',
  SPARK_WEBHOOKS: 'spark-webhooks',
  FREIGHT_OFFSET_CALCULATOR: 'offset-calculator',
  FREIGHT_FFA_SUBMISSION: 'ffa-submission',
  FREIGHT_FFA_DISCOVER: 'ffa-discover',
  FREIGHT_FFA_PREFIX: 'freight-ffa-',
  FREIGHT_FFA_SPARK30: 'freight-ffa-spark30',
  FREIGHT_FFA_SPARK25: 'freight-ffa-spark25',
  FREIGHT_FFA_PRICE_EVOLUTION: 'ffa-price-evolution',
  FREIGHT_FFA_SPREAD: 'ffa-spread',
  FREIGHT_ROUTES_DISCOVER: 'routes-discover',
  FREIGHT_INTRO: 'freight-intro',
  BASIS_INTRO: 'basis-intro',
  BASIS_SPARK_CLOSE_SANDBOX_INTRO: 'basis-spark-close-sandbox-intro',
  BASIS_PREMIUM: 'basis-premium',
  BASIS_DISCOVER: 'basis-discover',
  BASIS_PHYSICAL_PREFIX: 'basis-physical-',
  BASIS_PHYSICAL_SPARKNWE: 'basis-physical-sparknwe',
  BASIS_PHYSICAL_SPARKSWE: 'basis-physical-sparkswe',
  BASIS_SPARK_CLOSE: 'basis-spark-close',
  BASIS_SPARK_CLOSE_SANDBOX: 'basis-spark-close-sandbox',
  BASIS_SPARK_CLOSE_ADMIN: 'basis-spark-close-admin',
  BASIS_FFA_DISCOVER: 'basis-ffa-discover',
  BASIS_FFA_PREFIX: 'basis-ffa-',
  BASIS_FFA_SPARKNWE: 'basis-ffa-sparknwe',
  BASIS_FFA_SPARKSWE: 'basis-ffa-sparkswe',
  BASIS_FFA_SUBMISSION: 'basis-ffa-submission',
  BASIS_PRICE_EVOLUTION: 'basis-price-evolution',
  BASIS_ALERT_CENTRE: 'basis-alert-centre',
  BASIS_SETTLEMENT_TRACKER: 'basis-settlement-tracker',
  BASIS_NETBACK: 'basis-netback',
  BASIS_NETFORWARDS: 'basis-netforwards',
  BASIS_NETBACK_ARB_BREAKEVEN: 'basis-netback-arb-breakeven',
  NOTIFICATION_PREFERENCES: 'notification-preferences',
  MORE: 'more',
  HELP: 'help',
  TUTORIALS: 'tutorials',
  INTEGRATIONS: 'data-integrations',
  INTEGRATIONS_EXCEL_LEGACY: 'integrations-excel-legacy',
  INTEGRATIONS_EXCEL_V2: 'integrations-excel-v2',
  INTEGRATIONS_POWERBI: 'integrations-powerbi',
  INTEGRATIONS_API: 'integrations-api',
  INTEGRATIONS_WEBHOOKS: 'integrations-webhooks',
  INTEGRATIONS_WEBHOOKS_DETAILS: 'integrations-webhooks-details',
  INTEGRATIONS_ENVERUS: 'integrations-enverus',
  ACCESS_INTRO: 'access-intro',
  ACCESS_TERMINAL_MAP: 'access-terminal-map',
  ACCESS_TERMINAL_REGASIFICATION_COSTS_F:
    'access-terminal-regasification-costs-f',
  ACCESS_SPARKR_NETBACKS_F: 'access-sparkr-netbacks-f',
  ACCESS_SPARKR_FOB_NETBACKS_F: 'access-sparkr-fob-netbacks-f',
  ACCESS_SPARKR_FOB_NETBACKS_FO: 'access-sparkr-fob-netbacks-fo',
  ACCESS_TERMINAL_REGASIFICATION_COSTS_FO:
    'access-terminal-regasification-costs-fo',
  ACCESS_SPARKR_NETBACKS_FO: 'access-sparkr-netbacks-fo',
  ACCESS_TERMINAL_REGASIFICATION_COSTS_INTRO:
    'access-terminal-regasification-costs-intro',
  ACCESS_SPARKR_CHANGELOG: 'access-sparkr-changelog',
  ACCESS_SPARKRF_COSTS: 'access-sparkrf-costs',
  ACCESS_SPARKRFO_COSTS: 'access-sparkrfo-costs',
  ACCESS_HISTORICAL_DATA: 'access-historical-data',
  ACCESS_ALERT_CENTRE: 'access-alert-centre',
  ACCESS_CHANGELOG: 'access-changelog',
  SPARK_SIGNALS: 'spark-signals',
  SPARK_SIGNALS_INTRO: 'spark-signals-intro',
  INTRADAY_INTRO: 'intraday-intro',
  GAS_LEBA_THE: 'gas-leba-the',
  GAS_LEBA_TTF: 'gas-leba-ttf',
} as const;

// Global permissions list
export const permissions = {
  // Polls
  ACCESS_POLLS: 'access-polls-vote-and-results',
  // Prices
  DOWNLOAD_PRICES: 'download-prices',
  // Settlement Tracker
  ACCESS_SETTLEMENT_TRACKER_TIME_SERIES:
    'access-time-series-prices-front-month',
  ACCESS_SETTLEMENT_TRACKER: 'access-settlement-tracker-prices-front-month',
  // Spread Analysis
  ACCESS_PRICE_SPREADS: 'access-price-spreads',
  ACCESS_PRICE_CALCULATOR: 'access-price-calculator',
  ACCESS_GLOBAL_CALCULATOR: 'access-global-calculator',

  // floating-storage
  ACCESS_FLOATING_STORAGE: 'access-floating-storage',

  // Price-alert
  CREATE_PRICE_ALERTS: 'create-price-alerts',
  ACCESS_MULTI_MONTH_TOOL: 'access-multi-month-tool',
  // Opti
  ACCESS_OPTI_MVP: 'access-opti-mvp',
  // Historical Prices
  PREFIX_ACCESS_HISTORICAL_PRICE: 'access-historical-prices',
  PREFIX_ACCESS_SIGNALS_PRICE_EVOLUTION: 'access-signals-price-evolution',
  // Submit prices
  PREFIX_SUBMIT_PRICES: 'submit-prices',
  // Access Forward
  ACCESS_LATEST_PRICES_FORWARD: 'access-latest-prices-forward',
  // Offset Calculator
  ACCESS_OFFSET_CALCULATOR: 'access-offset-calculator',
  // Notification Preference
  PREFIX_ACCESS_NOTIFICATION_PREF: 'receive-price-release-by',
  // netback
  PREFIX_ACCESS_NETBACKS: 'access-netbacks',

  // notification pref
  SUFFIX_NOTIFICATION_PREF_WHATSAPP: 'by-whatsapp',
  SUFFIX_NOTIFICATION_PREF_EMAIL: 'by-email',
  SUFFIX_NOTIFICATION_PREF_PUSH: 'by-push',

  // ffa
  FFA_DOWNLOAD_PRICES: 'download-ffa-prices',
  FFA_ACCESS_LATEST_PRICES: 'access-latest-prices-ffa',
  PREFIX_ACCESS_FFA_SIGNALS_PRICE_EVOLUTION: 'access-signals-price-evolution',
  ACCESS_FFA_PRICE_SPREADS: 'access-ffa-price-spreads',
  ACCESS_FFA_TRACKER: 'access-ffa-tracker',
  ACCESS_FFA_SEASONALITY_TRACKER: 'access-ffa-seasonality-tracker',
  // Routes Discover page
  PREFIX_ACCESS_ROUTES: 'access-routes',

  // Access Platform
  ACCESS_PLATFORM_REQUEST_TERMINAL: 'access-platform-request-terminal',
  ACCESS_PLATFORM_SPARKR_TERMINAL_COSTS:
    'access-platform-sparkr-terminal-costs',
  ACCESS_PLATFORM_HISTORICAL_DATA: 'access-platform-historical-data',
  ACCESS_PLATFORM_SPARKR_CHANGELOG: 'access-platform-sparkr-changelog',

  // Basis Connect Platform
  ACCESS_CONNECT_MODULE: 'access-connect-module',

  // basis sandbox
  ACCESS_SPARK_CLOSE_SANDBOX: 'spark-close-operate-sandbox',

  // basis close
  ACCESS_SPARK_CLOSE: 'close-read',
  // MANAGE_SPARK_CLOSE: 'close-manage-orders',
  ACCESS_SPARK_CLOSE_ADMIN: 'close-admin-page-read',
  MANAGE_SPARK_CLOSE_ADMIN: 'close-admin-page-manage',
  PREFIX_CLOSE_ADMIN_ACCESS: 'close-admin-page',

  ACCESS_NETFORWARDS: 'access-netforwards',

  ACCESS_MULTIPLE_VERTICALS_SIGNALS: 'access-multi-verticals-signals',
};

// For featureVersion red-dot indicator
export const FREIGHT_DASHBOARD_FEATURES = {
  DISCOVERY: 'discovery',
  SIGNALS: 'signals',
  SPREAD_CHARTING: 'spread-charting',
  SETTLEMENT_TRACKER: 'settlement-tracker',
};

export const FREIGHT_DISCOVER_FEATURES = {
  ALL: 'all',
  COMPARE_CHARTS: 'compare-charts',
  INSIGHTS: 'freight-insights',
};

export const FREIGHT_PHYSICAL_SPARK25_FEATURES = {
  ALL: 'all',
};

export const FREIGHT_PHYSICAL_SPARK30_FEATURES = {
  ALL: 'all',
};

export const FREIGHT_FFA_SPARK25_FEATURES = {
  ALL: 'all',
};

export const FREIGHT_FFA_SPARK30_FEATURES = {
  ALL: 'all',
};

export const FREIGHT_PRICE_EVOLUTION_FEATURES = {
  ALL: 'all',
  DELTA_BOXES: 'deltaboxes',
  PRICE_EVOLUTION_CHART: 'priceevolutionchart',
  PRICE_DELTA_CHART: 'pricedeltachart',
  PRICE_EVOLUTION_TABLE: 'priceevolutiontable',
};

export const FREIGHT_OPTI_FEATURES = {
  ALL: 'all',
};

export const FREIGHT_PRICE_ALERTS_FEATURES = {
  ALL: 'all',
};

export const FREIGHT_SPREAD_FEATURES = {
  ALL: 'all',
};

export const FREIGHT_FFA_SPREAD_FEATURES = {
  ALL: 'all',
};

export const FREIGHT_PRICE_CALCULATOR = {
  ALL: 'all',
};

export const FREIGHT_SPARK_DATA_FEATURES = {
  ALL: 'all',
  INTRODUCTION: 'introduction',
  GETTING_STARTED: 'gettingstarted',
  REGISTER: 'register',
  AUTHENTICATE: 'authenticate',
  CONNECT: 'connect',
};

export const FREIGHT_NOTIF_PREF_FEATURES = {
  ALL: 'all',
  WHATSAPP_NUMBER: 'whatsappnumber',
  LNG_FREIGHT: 'lng-freight',
  LNG_BASIS: 'lng-basis',
};

export const FREIGHT_OFFSET_CALCULATOR = {
  ALL: 'all',
  CALCULATOR: 'calculator',
  ACTIVITY_FEED: 'activity-feed',
  TREE_PROGRESS: 'tree-progress',
};

export const FREIGHT_FFA_SUBMISSION = {
  ALL: 'all',
};

export const FREIGHT_ROUTES_DISCOVER_FEATURES = {
  ALL: 'all',
  DOWNLOAD: 'routes-download',
  DOWNLOAD_XLS: 'routes-download-xls',
};

export const FREIGHT_FFA_DISCOVER_FEATURES = {
  ALL: 'all',
  COMPARE_CHARTS: 'ffa-compare-charts',
};

export const SPARK_WEBHOOKS = {
  ALL: 'all',
  INTRODUCTION: 'introduction',
};

export const BASIS_DISCOVER_FEATURES = {
  ALL: 'all',
  COMPARE_CHARTS: 'basis-compare-charts',
};

export const BASIS_FFA_DISCOVER_FEATURES = {};

export const BASIS_PHYSICAL_SPARKNWE_FEATURES = {
  ALL: 'all',
};

export const BASIS_PHYSICAL_SPARKSWE_FEATURES = {
  ALL: 'all',
};

export const BASIS_FFA_SPARKNWE_FEATURES = {
  ALL: 'all',
};

export const BASIS_FFA_SPARKSWE_FEATURES = {
  ALL: 'all',
};

export const BASIS_PRICE_EVOLUTION = {
  ALL: 'all',
};

export const FREIGHT_FFA_PRICE_EVOLUTION = {
  ALL: 'all',
  DELTA_BOXES: 'deltaboxes',
  PRICE_EVOLUTION_CHART: 'priceevolutionchart',
  PRICE_DELTA_CHART: 'pricedeltachart',
  PRICE_EVOLUTION_TABLE: 'priceevolutiontable',
};

export const BASIS_NETBACK = {
  ALL: 'all',
};

export const ACCESS_SPARKR_CHANGELOG = {
  ALL: 'all',
};

export const ACCESS_SPARKRF_COSTS = {
  ALL: 'all',
  DES_HUB_NETBACKS: 'des-hub-netbacks',
  HUB_SLIPPAGE: 'hub-slippage',
};

export const ACCESS_SPARKRFO_COSTS = {
  ALL: 'all',
};

export const SPARK_SIGNALS = {
  ALL: 'all',
};

export enum SubmissionState {
  NoReleaseToday = 'no-release-today',
  PreWindow = 'pre-window',
  SubmissionOpen = 'submission-open',
  SubmissionPubliclyOpen = 'submission-publicly-open',
  SubmissionExtended = 'submission-extended',
  ClosedPendingRelease = 'closed-pending-release',
  PriceReleasedToday = 'price-released-today',
  Unknown = 'unknown',
}

export const SUBMISSION_MESSAGES = {
  submitter: {
    [SubmissionState.NoReleaseToday]: 'Opens {windowOpenDate}',

    [SubmissionState.PreWindow]: 'Window opens {windowOpenDate}',

    [SubmissionState.SubmissionOpen]: 'Window opens {windowOpenDate}',

    [SubmissionState.SubmissionPubliclyOpen]: 'Open until {windowCloseDate}',
    [SubmissionState.SubmissionExtended]:
      'Window extended due to late submissions. Closing soon.',
    [SubmissionState.ClosedPendingRelease]: 'Closed, price release shortly.',
    [SubmissionState.PriceReleasedToday]: 'Closed, new price available.',
    [SubmissionState.Unknown]: 'Not available',
  },
  'non-submitter': {
    [SubmissionState.NoReleaseToday]: 'Opens {windowOpenDate}',

    [SubmissionState.PreWindow]: 'Window opens {windowOpenDate}',

    [SubmissionState.SubmissionOpen]: 'Window opens {windowOpenDate}',

    [SubmissionState.SubmissionPubliclyOpen]: 'Open until {windowCloseDate}',
    [SubmissionState.SubmissionExtended]:
      'We are receiving final submissions and will publish the price as soon as possible.',
    [SubmissionState.ClosedPendingRelease]: 'Closed, price release shortly.',
    [SubmissionState.PriceReleasedToday]: 'Closed, new price available.',
    [SubmissionState.Unknown]: 'Not available',
  },
};

export enum ContractSubmissionDisplayState {
  Submitted = 'Submitted',
  SubmittedByTeam = 'Submitted by your team',
  SubmittedByColleague = 'Submitted by colleague',
}

export const FREIGHT_ALERT_CENTRE = {
  ALL: 'all',
  WHATSAPP: 'whatsapp',
  NOTIFICATION_PREFERENCES: 'notification-preferences',
  USER_DEFINED_ALERTS: 'user-defined-alerts',
};

export const INTEGRATIONS = {
  ALL: 'all',
  EXCEL: 'excel',
  API: 'api',
  WEBHOOKS: 'webhooks',
  ENVERUS: 'enverus',
  MORNINGSTAR: 'morningstar',
  ZEMA: 'zema',
};

export const GDPR_CONSENT_KEY = 'gdpr_consent';

export const RT_CALCULATOR_STORAGE_KEY = 'rt-calculator-results-v1';
export const RT_CALCULATOR_STORAGE_CRITERIA_KEY = 'rt-calculator-criteria-v1';
export const RT_CALC_SUBMISSION_STORAGE_KEY =
  'rt-calculator-results-submission-v1';
export const RT_CALC_SUBMISSION_STORAGE_CRITERIA_KEY =
  'rt-calculator-criteria-submission-v1';

export const INACTIVE_HOVER_INDEX = -1;
export const SubmissionValidationMessages = {
  required: {
    code: 'FIELD_VALUE_REQUIRED',
    message: 'Please input a price.',
  },
  roundoff: {
    code: 'FIELD_VALUE_ROUNDOFF_MSG',
    message: 'System has rounded-off the price.',
  },
  softDeviation: {
    code: 'FIELD_SOFT_RANGE_DEV_MSG',
    message: 'Submission is a significant change from previous Spark release.',
  },
  softDeviationWithJustification: {
    code: 'FIELD_SOFT_RANGE_JUSTIFICATION_DEV_MSG',
    message:
      'A justification is required for this significant change from your previous Spark release submission.',
  },
  hardDeviation: {
    code: 'FIELD_HARD_RANGE_ERROR_MSG',
    message: 'Submission is outside the range set for this release.',
  },
  sourceRoundTripCalculator: {
    code: 'FIELD_SOURCE_RT_CALC',
    message: 'Price applied from Round Trip Calculator.',
  },
};

export const DEFAULT_ERROR_MESSAGE =
  'Sorry we cannot process your request, please contact us.';
export const DEFAULT_SUCCESS_MESSAGE = 'We have received your request.';

export const PLOTLY_IMAGE_DOWNLOAD_OPTIONS = {
  format: 'png' as const,
  width: 700,
  height: 450,
};
