import type { components, paths } from '@/types';

import ApiBase from './ApiBase';

export default class ConnectService extends ApiBase {
  SERVICE_BASE_URL = `v1.0/connect`;
  MAPS_SERVICE_BASE_URL = `v1.0/maps`;
  HUBS_SERVICE_BASE_URL = `v1.0/hubs`;

  async loadPosts() {
    const response = await this.$http.get<
      components['schemas']['ListResponse_SlotSwapInterestDTO_']
    >(`${this.SERVICE_BASE_URL}/slot-swaps`);
    return response.data.data;
  }

  async loadPorts(projection = 'usgc') {
    const response = await this.$http.get<
      components['schemas']['DetailResponse_PAPIMapFOBPortsDTO_']
    >(`${this.MAPS_SERVICE_BASE_URL}/ports/${projection}`);
    return response.data.data;
  }

  async loadHubsData(projection = 'usgc') {
    const response = await this.$http.get<
      components['schemas']['DetailResponse_HubDTO_']
    >(`${this.HUBS_SERVICE_BASE_URL}/${projection}`);
    return response.data.data;
  }

  async postConnectSlotSwap(data: components['schemas']['NewSlotSwapPayload']) {
    const path = '/v1.0/connect/slot-swaps' as keyof paths;
    type operation = paths['/v1.0/connect/slot-swaps']['post'];
    type successResponse =
      operation['responses'][201]['content']['application/json'];

    const response = await this.$http.post<successResponse>(path, data, {
      headers: {
        'content-type': 'application/json',
      },
    });
    const responseData = response.data.data;
    return responseData;
  }

  async connectConnectSlotSwap(
    slotSwapUuid: string,
    data: components['schemas']['ConnectToSlotSwapPayload'],
  ) {
    const path =
      `/v1.0/connect/slot-swaps/${slotSwapUuid}/connections` as keyof paths;
    type operation =
      paths['/v1.0/connect/slot-swaps/{ssi_uuid}/connections']['post'];
    type successResponse =
      operation['responses'][201]['content']['application/json'];

    const response = await this.$http.post<successResponse>(path, data, {
      headers: {
        'content-type': 'application/json',
      },
    });
    const responseData = response.data.data;
    return responseData;
  }
}
