import ApiBase from '@/core/services/ApiBase';
import type { paths } from '@/types';

export default class GlobalCalcService extends ApiBase {
  async getReferenceData() {
    const path = 'v1.0/global-calculator/reference-data';

    type Response =
      paths['/v1.0/global-calculator/reference-data']['get']['responses']['200']['content']['application/json'];

    const response = await this.$http.get<Response>(path);
    return response?.data;
  }

  async getCalculationsByUuid(uuid: string) {
    const path = `v1.0/global-calculator/calculations/${uuid}`;

    type Response =
      paths[`/v1.0/global-calculator/calculations/{uuid}`]['get']['responses']['200']['content']['application/json'];

    const response = await this.$http.get<Response>(path);
    return response?.data;
  }

  // async getCalculations() {
  //   const path = `v1.0/global-calculator/calculations`;

  //   type Response =
  //     paths[`/v1.0/global-calculator/calculations`]['get']['responses']['200']['content']['application/json'];

  //   const response = await this.$http.get<Response>(path);
  //   return response?.data;
  // }

  async saveCalculations(name: string, data: any) {
    const path = `v1.0/global-calculator/calculations`;

    type Response =
      paths[`/v1.0/global-calculator/calculations`]['post']['responses']['201']['content']['application/json'];

    const response = await this.$http.post<Response>(path, {
      name,
      parameters: data,
    });
    return response?.data;
  }
}
