import type {
  NavigationGuardNext,
  RouteLocation,
  RouteRecordRaw,
} from 'vue-router';

import SideSplashLayout from '@/core/components/layout/SideSplashLayout.vue';
import accessRoutes from '@/products/access/router';
import cargoRoutes from '@/products/basis/router';
import basisRedirects from '@/products/basis/router/basisRedirects';
import freightRoutes from '@/products/freight/router';
import gasRoutes from '@/products/gas/router';
import AppLayout from '@/shared/layout/AppLayout.vue';
import FullScreenLayout from '@/shared/layout/FullScreenLayout.vue';
import { features, permissions } from '@/shared/misc/constants';

import ErrorPageVue from '../pages/ErrorPage.vue';
import { useAuthStore } from '../stores/auth';
import { useWebhookStore } from '../stores/webhook';

export const constantRouteMap: RouteRecordRaw[] = [
  ...freightRoutes,
  ...basisRedirects,
  ...cargoRoutes,
  ...accessRoutes,
  ...gasRoutes,
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
  },
  {
    path: '/freight-intro',
    name: features.FREIGHT_INTRO,
    component: () => import('@/products/freight/pages/intro/FreightIntro.vue'),
    meta: {
      title: 'Freight',
      requiresAuth: true,
      layout: AppLayout,
      minimizeSideMenu: true,
    },
  },
  {
    path: '/cargo-intro',
    name: features.BASIS_INTRO,
    component: () => import('@/products/basis/pages/intro/CargoIntro.vue'),
    meta: {
      title: 'Basis',
      requiresAuth: true,
      layout: AppLayout,
      minimizeSideMenu: true,
    },
  },
  {
    path: '/access-intro',
    name: features.ACCESS_INTRO,
    component: () => import('@/products/access/pages/intro/AccessIntro.vue'),
    meta: {
      title: 'Access',
      requiresAuth: true,
      layout: AppLayout,
      minimizeSideMenu: true,
    },
  },
  {
    path: '/intraday-intro',
    name: features.INTRADAY_INTRO,
    component: () =>
      import('@/products/intraday/pages/intro/IntradayIntro.vue'),
    meta: {
      title: 'Intraday',
      requiresAuth: true,
      layout: AppLayout,
    },
  },
  {
    path: '/more',
    name: 'more',
    component: () => import('@/core/pages/MorePage.vue'),
    meta: {
      title: 'Governance & More',
      requiresAuth: true,
      layout: AppLayout,
    },
  },
  {
    path: '/spark-signals-intro',
    name: features.SPARK_SIGNALS_INTRO,
    component: () =>
      import('@/core/pages/spark-signals/SparkSignalsIntroPage.vue'),
    meta: {
      title: 'Spark Signals',
      requiresAuth: true,
      layout: AppLayout,
    },
  },
  {
    path: '/spark-signals',
    name: features.SPARK_SIGNALS,
    component: () => import('@/core/pages/spark-signals/SparkSignalsPage.vue'),
    meta: {
      title: 'Spark Signals',
      requiresAuth: true,
      layout: AppLayout,
    },
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore();
      const noFreightAccess = authStore.isFeatureLocked(
        permissions.ACCESS_MULTIPLE_VERTICALS_SIGNALS,
        'lng-freight-platform',
        'startsWith',
      );
      const noCargoAccess = authStore.isFeatureLocked(
        permissions.ACCESS_MULTIPLE_VERTICALS_SIGNALS,
        'lng-basis-platform',
        'startsWith',
      );
      if (noFreightAccess && noCargoAccess) {
        next('/spark-signals-intro');
      } else {
        next();
      }
    },
  },
  {
    path: '/data-integrations',
    component: () =>
      import('@/core/pages/integrations/components/IntegrationsLayout.vue'),
    children: [
      {
        path: '',
        name: features.INTEGRATIONS,
        component: () => import('@/core/pages/integrations/index.vue'),
        meta: {
          title: 'Introduction',
          requiresAuth: true,
          basic: true,
          layout: AppLayout,
        },
      },
      {
        path: 'excel-legacy',
        component: () => import('@/core/pages/integrations/ExcelLegacy.vue'),
        name: features.INTEGRATIONS_EXCEL_LEGACY,
        meta: {
          title: 'Excel Add-in (Legacy)',
          requiresAuth: true,
          basic: true,
          layout: AppLayout,
        },
      },
      {
        path: 'excel',
        component: () => import('@/core/pages/integrations/ExcelV2.vue'),
        name: features.INTEGRATIONS_EXCEL_V2,
        meta: {
          title: 'Excel Add-in',
          requiresAuth: true,
          basic: true,
          layout: AppLayout,
        },
      },
      {
        path: 'powerbi',
        component: () => import('@/core/pages/integrations/PowerBi.vue'),
        name: features.INTEGRATIONS_POWERBI,
        meta: {
          title: 'Power BI',
          requiresAuth: true,
          basic: true,
          layout: AppLayout,
        },
      },
      {
        path: 'api',
        name: features.INTEGRATIONS_API,
        component: () =>
          import('@/core/pages/integrations/spark-api/index.vue'),
        alias: ['/spark-data'],
        meta: {
          title: 'Spark API',
          requiresAuth: true,
          basic: true,
          layout: AppLayout,
        },
      },
      {
        path: 'enverus',
        name: features.INTEGRATIONS_ENVERUS,
        component: () => import('@/core/pages/integrations/Enverus.vue'),
        meta: {
          title: 'Enverus',
          requiresAuth: true,
          basic: true,
          layout: AppLayout,
        },
      },
      {
        path: 'webhooks',
        component: () => import('@/core/pages/integrations/webhooks/index.vue'),
        name: features.INTEGRATIONS_WEBHOOKS,
        meta: {
          title: 'Spark Webhooks',
          requiresAuth: true,
          basic: true,
          layout: AppLayout,
        },
        beforeEnter: async (
          to: RouteLocation,
          from: RouteLocation,
          next: NavigationGuardNext,
        ) => {
          const webhookStore = useWebhookStore();
          await webhookStore.getWebhooksMeta();
          await webhookStore.getWebhooks();
          next();
        },
      },
      {
        path: 'webhooks/:uuid',
        component: () =>
          import('@/core/pages/integrations/webhooks/WebhookDetails.vue'),
        name: features.INTEGRATIONS_WEBHOOKS_DETAILS,
        meta: {
          title: 'Webhook Detail',
          requiresAuth: true,
          basic: true,
          layout: AppLayout,
        },
        beforeEnter: async (
          to: RouteLocation,
          from: RouteLocation,
          next: NavigationGuardNext,
        ) => {
          const webhookStore = useWebhookStore();
          await webhookStore.getWebhooksMeta();
          await webhookStore.getWebhooks();
          next();
        },
      },
    ],
  },
  {
    path: '/tutorials',
    name: features.TUTORIALS,
    component: () => import('@/core/pages/tutorials/TutorialsPage.vue'),
    meta: {
      title: 'Tutorials',
      requiresAuth: true,
      layout: AppLayout,
    },
  },
  {
    path: '/account/profile',
    component: () => import('@/core/pages/account/ProfilePage/index.vue'),
    meta: {
      title: 'Profile',
      requiresAuth: true,
      layout: AppLayout,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/core/pages/account/LoginPage.vue'),
    meta: {
      title: 'Login',
      requiresAuth: false,
      layout: SideSplashLayout,
      basic: true,
    },
  },
  {
    path: '/logout',
    component: () => import('@/core/pages/account/LogoutPage.vue'),
    name: 'logout',
    meta: {
      title: 'Logout',
      layout: SideSplashLayout,
      basic: true,
    },
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () => import('@/core/pages/account/PasswordResetPage.vue'),
    meta: {
      title: 'Reset Password',
      requiresAuth: false,
      layout: SideSplashLayout,
      basic: true,
    },
  },
  {
    path: '/dashboard',
    name: 'core-dashboard',
    component: () => import('@/core/pages/DashboardPage.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
      layout: AppLayout,
      basic: false,
      keepAlive: false,
      minimizeSideMenu: true,
    },
  },
  {
    path: '/onboarding',
    component: () => import('@/core/pages/account/OnboardingPage/index.vue'),
    children: [
      {
        path: '',
        component: () =>
          import('@/core/pages/account/OnboardingPage/Intro.vue'),
        meta: {
          title: 'Onboarding',
          requiresAuth: true,
          layout: FullScreenLayout,
        },
      },
      {
        path: 'content',
        component: () =>
          import(
            '@/core/pages/account/OnboardingPage/ProductOnboardingContent.vue'
          ),
        meta: {
          title: 'Onboarding',
          requiresAuth: true,
          layout: FullScreenLayout,
        },
      },
      {
        path: 'profile',
        component: () => import('@/core/pages/account/ProfilePage/index.vue'),
        meta: {
          title: 'Onboarding',
          requiresAuth: true,
          layout: FullScreenLayout,
        },
      },
    ],
  },
  {
    path: '/signup',
    component: () => import('@/core/pages/account/SignUpPage/SignUpPage.vue'),

    children: [
      {
        path: '',
        name: 'signup-1',
        component: () =>
          import('@/core/pages/account/SignUpPage/ProductStep.vue'),
        meta: {
          title: 'Signup',
          requiresAuth: false,
          step: 0, // for a-steps in page
          layout: SideSplashLayout,
          basic: true,
        },
      },
      {
        path: '2',
        name: 'signup-2',
        component: () =>
          import('@/core/pages/account/SignUpPage/ProfileStep.vue'),
        meta: {
          title: 'Signup',
          requiresAuth: false,
          step: 1, // for a-steps in page
          layout: SideSplashLayout,
          basic: true,
        },
      },
      {
        path: '3',
        name: 'signup-3',
        component: () =>
          import('@/core/pages/account/SignUpPage/LoginDetailsStep.vue'),
        meta: {
          title: 'Signup',
          requiresAuth: false,
          step: 2, // for a-steps in page
          layout: SideSplashLayout,
          basic: true,
        },
      },
    ],
  },
  {
    path: '/password-change',
    name: 'password-change',
    component: () => import('@/core/pages/account/PasswordChangePage.vue'),
    meta: {
      title: 'Change Password',
      layout: SideSplashLayout,
      basic: true,
    },
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPageVue,
    meta: {
      title: 'Error',
      basic: true,
      layout: FullScreenLayout,
    },
  },
  {
    path: '/welcome-kpler',
    name: 'welcome-kpler',
    component: () => import('@/core/pages/WelcomeKplerPage.vue'),
    meta: {
      title: 'Welcome Kpler',
      requiresAuth: false,
      basic: true,
      layout: FullScreenLayout,
    },
  },
  {
    path: '/slot-swap-hub',
    name: 'map',
    component: () => import('@/core/pages/hub/SparkHubPage.vue'),
    meta: {
      title: 'Hubs',
      requiresAuth: true,
      minimizeSideMenu: true,
      layout: AppLayout,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/core/pages/account/ForbiddenPage.vue'),
    meta: {
      title: 'Access Denied',
      requiresAuth: true,
      hideBannerMessage: true,
      basic: true,
      layout: AppLayout,
    },
  },
  {
    path: '/404',
    name: '404',
    redirect: '/',
    meta: {
      title: 'Page Not Found',
      basic: true,
      layout: FullScreenLayout,
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '404',
  },
];
