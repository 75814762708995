<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useAuthStore } from '@/core/stores/auth';
import { useMenuStore } from '@/core/stores/menu';
import SparkScroller from '@/shared/components/SparkScroller.vue';

import CallToActionLink from './CallToActionLink.vue';
import TheSidebarSubMenuSectionLink from './TheSidebarSubMenuSectionLink.vue';
import TheSidebarSubMenuSectionParent from './TheSidebarSubMenuSectionParent.vue';

const PLAN: Record<string, string> = {
  'trial-plan': 'Trial',
  'basic-plan': 'Basic',
};

const route = useRoute();
const menu = useMenuStore();
const authStore = useAuthStore();

const {
  freightMenu,
  freightCallToActions,
  cargoMenu,
  cargoCallToActions,
  accessMenu,
  accessCallToActions,
  lebaMenu,
  lebaCallToActions,
} = storeToRefs(menu);

const { freightPlan, cargoPlan, accessPlan } = storeToRefs(authStore);

const scroller = ref<InstanceType<typeof SparkScroller> | null>(null);

const isFreightRoute = computed(
  () => route.meta.product === 'lng-freight-platform',
);

const isCargoRoute = computed(
  () => route.meta.product === 'lng-basis-platform',
);

const isAccessRoute = computed(() => route.meta.product === 'access-platform');

const isLebaRoute = computed(() => route.meta.product === 'gas-leba-platform');

const subscriptionPlan = computed(() => {
  if (isFreightRoute.value) {
    return freightPlan.value;
  }
  if (isCargoRoute.value) {
    return cargoPlan.value;
  }
  if (isAccessRoute.value) {
    return accessPlan.value;
  }
  if (isLebaRoute.value) {
    return freightPlan.value;
  }
  return undefined;
});

const title = computed(() => {
  if (isFreightRoute.value) {
    return 'Freight';
  }
  if (isCargoRoute.value) {
    return 'Cargo';
  }
  if (isAccessRoute.value) {
    return 'Access';
  }
  if (isLebaRoute.value) {
    return 'Gas';
  }
  return '';
});

const titleColor = computed(() => {
  if (isFreightRoute.value) {
    return 'text-green-500';
  }
  if (isCargoRoute.value) {
    return 'text-basis-400';
  }
  if (isAccessRoute.value) {
    return 'text-access-500';
  }
  if (isLebaRoute.value) {
    return 'text-gas-500';
  }
  return '';
});

const sections = computed(() => {
  if (isFreightRoute.value) {
    return freightMenu.value;
  }
  if (isCargoRoute.value) {
    return cargoMenu.value;
  }
  if (isAccessRoute.value) {
    return accessMenu.value;
  }
  if (isLebaRoute.value) {
    return lebaMenu.value;
  }
  return [];
});

const callToActions = computed(() => {
  if (isFreightRoute.value) {
    return freightCallToActions.value;
  }
  if (isCargoRoute.value) {
    return cargoCallToActions.value;
  }
  if (isAccessRoute.value) {
    return accessCallToActions.value;
  }
  if (isLebaRoute.value) {
    return lebaCallToActions.value;
  }
  return [];
});

watch(title, (titleValue) => {
  if (titleValue !== '') {
    if (scroller.value) {
      scroller.value.scrollToTop();
    }
  }
});
</script>

<template>
  <SparkScroller
    ref="scroller"
    :space-between-content-and-scrollbar="0"
    :scrollbar-width="0"
    :mask-height="48"
    class="relative h-full max-h-full"
  >
    <div class="mr-2 border-r border-gray-700 bg-[#222a38] pb-5 pl-5">
      <div class="flex items-center justify-between pr-3">
        <div
          class="flex h-[48px] items-center text-base font-bold"
          :class="[titleColor]"
        >
          {{ title }}
        </div>

        <div
          v-if="
            subscriptionPlan === 'basic-plan' ||
            subscriptionPlan === 'trial-plan'
          "
          class="ml-auto rounded bg-orange-600 px-1 py-0.5 text-[0.6rem] font-semibold leading-none text-white"
        >
          {{ PLAN[subscriptionPlan] }}
        </div>
      </div>

      <div class="space-y-4">
        <div v-if="callToActions.length" class="mt-[1px]">
          <CallToActionLink
            v-for="cta in callToActions"
            :key="cta.description"
            :as="cta.renderAs"
            :href="cta.href"
            :open-crisp-chat-if-available="cta.openCrispChatIfAvailable"
            :pre-fill-crisp-message="cta.preFillCrispChatMessage"
          >
            {{ cta.description }}
          </CallToActionLink>
        </div>

        <div class="space-y-8">
          <div
            v-for="section in sections"
            :key="section.title"
            class="space-y-4"
          >
            <div class="text-sm font-bold tracking-widest text-gray-400">
              {{ section.title }}
            </div>
            <div class="space-y-2">
              <template v-for="item in section.items" :key="item.title">
                <TheSidebarSubMenuSectionParent
                  v-if="!item.to && item.items"
                  :id="item.uiTourId"
                  :icon="item.icon"
                  :title="item.title"
                  :items="item.items"
                  :is-locked="item.isLocked"
                  :show-red-dot="item.showRedDot"
                />
                <TheSidebarSubMenuSectionLink
                  v-if="item.to && !item.items"
                  :to="item.to"
                  :icon="item.icon"
                  :title="item.title"
                  :sub-title="item.subTitle"
                  :is-locked="item.isLocked"
                  :show-red-dot="item.showRedDot"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SparkScroller>
</template>
